:root { 
  --black: #000000;
  --east-bay: #3e5d83;
  --forest-green: #0d8225;
  --horizon: #5a8da4;
  --log-cabin: #212121;
  --mine-shaft: #333333;
  --perano: #b1c4f5;
  --shakespeare: #4e99d3;
  --white: #ffffff;
 
  --font-size-l2: 20px;
  --font-size-s: 22px;
  --font-size-xl2: 28px;
  --font-size-xl22: 72px;
  --font-size-xl3: 30px;
  --font-size-xs2: 14px;
  --font-size-xxxs: 0.1px;
 
  --font-family-inter: "Inter", Helvetica;
  --font-family-m_plus_1: "M PLUS 1", Helvetica;
  --font-family-mplus_1p-extrabold: "Mplus 1p-ExtraBold", Helvetica;
  --font-family-mplus_1p-medium: "Mplus 1p-Medium", Helvetica;
  --font-family-mplus_1p-regular: "Mplus 1p-Regular", Helvetica;
  --font-family-noto_sans_jp: "Noto Sans JP", Helvetica;
  --font-family-oswald: "Oswald", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
}
.oswald-normal-shakespeare-72px {
  color: var(--shakespeare);
  font-family: var(--font-family-oswald);
  font-size: var(--font-size-xl22);
  font-style: normal;
  font-weight: 400;
}

.mplus1p-medium-east-bay-30px {
  color: var(--east-bay);
  font-family: var(--font-family-mplus_1p-medium);
  font-size: var(--font-size-xl3);
  font-style: normal;
  font-weight: 500;
}

.mplus1-normal-horizon-20px {
  color: var(--horizon);
  font-family: var(--font-family-m_plus_1);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.mplus1p-extra-extra-bold-black-22px {
  color: var(--black);
  font-family: var(--font-family-mplus_1p-extrabold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 800;
}

.inter-bold-black-28px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 700;
}

.mplus1-normal-white-28px {
  color: var(--white);
  font-family: var(--font-family-m_plus_1);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 400;
}

.inter-bold-black-40px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.mplus1-bold-black-13px {
  color: var(--black);
  font-family: var(--font-family-m_plus_1);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.mplus1p-extra-extra-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-mplus_1p-extrabold);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
}
