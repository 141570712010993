/* .whitespace{
    width: 2200px;
} */

.full_page{
    width: 2000px;
    /* border: 2px solid; */
    /* position: relative;
    left: 100px; */
    /* height: 3000px;  */
}

.page2{
    height: 1590px;
    /* position: absolute; */
    /* top: 1590px; */
}

.page1{
    height: 1590px;
    /* position: absolute; */
    /* top: 1590px; */
}

.box_header{
    min-height: 120px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    top: 15;
    /* border: 2px solid; */
    /* left: 100; */
  }

  .box_header_2{
    min-height: 120px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    top: 30;
    /* border: 2px solid; */
    /* left: 100; */
  }

.date{
    width: 240px;
    position: absolute;
    top: 35px;
    left: 190;
    
}

.line {
    align-items: center;
    width: 100%;
    border-bottom: 3px solid rgba(122, 122, 122, 0.999);
    position: absolute;
    top: 60;
}

.area_text{
    /* align-items: left;
    display: flex; */
    position: absolute;
    top: 35px;
    left: 20;
    font-family: var(--font-family-m_plus_1);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;

}

.image_kuma {
    height: 60px;
    width: 60px;
    position: absolute;
    top: -50px;
    left: 400px;
  }

.image_logo {
    width: 12%;
    height: auto;
    position: absolute;
    top: -60px;
    left: 1745px;
}

.text_by {
    font-size: 120%;
    position: absolute;
    top: 6px;
    left: 1700px;
    color: #0c60ac;
}

.image_byaf {
    height: 65px;
    width: 12%;
    position: absolute;
    top: -5px;
    left: 1720px
}

.table{
    width: 100%;
    /* height: 50%; */
    position: relative;
    top: 20;
    left: 60;
}


.hanrei{
    min-height: 120px;
    width: 800px;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    top: 15;
    left: 20;
    /* border: 2px solid; */
}

.keisan{
    position: relative;
    top: 25; 
    /* left: 10; */
}

.Text{
    position: relative;
    top: 25; 
}

.Graph{
    width: 100%;
    position: relative;
    left: 17;
    top:80;
}

/* jepxChart */
.jepxchart{
    width:640px;
    height:390px;
    border: 1px solid #d9d9d9;
    position: relative;
}
.LineChart{
    position: absolute;
    top: 75px;
}

.header_chart{
    width: 100%;
    height: 50px;
    /* border: 2px solid; */
    background-color: #f2f2f2;
    /* border: 1px solid white; */
}

.bold-text {
    font-weight: bold;
  }