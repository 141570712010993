@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Inter:400,700|M+PLUS+1:400,700|Oswald:400|Roboto:300|Noto+Sans+JP:500");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6379dd0f64e76627f727753c.6379dd100b991b29efcfc9af.639be818d6682dccb5ef0952.rcp.png");

@font-face {
  font-family: "Mplus 1p-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Mplus1p-Regular") format("truetype");
}
@font-face {
  font-family: "Mplus 1p-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.animaapp.com/Mplus1p-Medium") format("truetype");
}
@font-face {
  font-family: "Mplus 1p-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("https://fonts.animaapp.com/Mplus1p-ExtraBold") format("truetype");
}


.screen a {
  display: contents;
  text-decoration: none;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
