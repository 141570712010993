@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Inter:400,700|M+PLUS+1:400,700|Oswald:400|Roboto:300|Noto+Sans+JP:500";
@import "https://px.animaapp.com/6379dd0f64e76627f727753c.6379dd100b991b29efcfc9af.639be818d6682dccb5ef0952.rcp.png";
@font-face {
  font-family: "Mplus 1p-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Mplus1p-Regular") format("truetype");
}

@font-face {
  font-family: "Mplus 1p-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.animaapp.com/Mplus1p-Medium") format("truetype");
}

@font-face {
  font-family: "Mplus 1p-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("https://fonts.animaapp.com/Mplus1p-ExtraBold") format("truetype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

:root {
  --black: #000;
  --east-bay: #3e5d83;
  --forest-green: #0d8225;
  --horizon: #5a8da4;
  --log-cabin: #212121;
  --mine-shaft: #333;
  --perano: #b1c4f5;
  --shakespeare: #4e99d3;
  --white: #fff;
  --font-size-l2: 20px;
  --font-size-s: 22px;
  --font-size-xl2: 28px;
  --font-size-xl22: 72px;
  --font-size-xl3: 30px;
  --font-size-xs2: 14px;
  --font-size-xxxs: .1px;
  --font-family-inter: "Inter", Helvetica;
  --font-family-m_plus_1: "M PLUS 1", Helvetica;
  --font-family-mplus_1p-extrabold: "Mplus 1p-ExtraBold", Helvetica;
  --font-family-mplus_1p-medium: "Mplus 1p-Medium", Helvetica;
  --font-family-mplus_1p-regular: "Mplus 1p-Regular", Helvetica;
  --font-family-noto_sans_jp: "Noto Sans JP", Helvetica;
  --font-family-oswald: "Oswald", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
}

.oswald-normal-shakespeare-72px {
  color: var(--shakespeare);
  font-family: var(--font-family-oswald);
  font-size: var(--font-size-xl22);
  font-style: normal;
  font-weight: 400;
}

.mplus1p-medium-east-bay-30px {
  color: var(--east-bay);
  font-family: var(--font-family-mplus_1p-medium);
  font-size: var(--font-size-xl3);
  font-style: normal;
  font-weight: 500;
}

.mplus1-normal-horizon-20px {
  color: var(--horizon);
  font-family: var(--font-family-m_plus_1);
  font-size: var(--font-size-l2);
  font-style: normal;
  font-weight: 400;
}

.mplus1p-extra-extra-bold-black-22px {
  color: var(--black);
  font-family: var(--font-family-mplus_1p-extrabold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 800;
}

.inter-bold-black-28px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 700;
}

.mplus1-normal-white-28px {
  color: var(--white);
  font-family: var(--font-family-m_plus_1);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 400;
}

.inter-bold-black-40px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.mplus1-bold-black-13px {
  color: var(--black);
  font-family: var(--font-family-m_plus_1);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.mplus1p-extra-extra-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-mplus_1p-extrabold);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
}

.full_page {
  width: 2000px;
}

.page2, .page1 {
  height: 1590px;
}

.box_header {
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  display: flex;
  position: relative;
  top: 15px;
}

.box_header_2 {
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  display: flex;
  position: relative;
  top: 30px;
}

.date {
  width: 240px;
  position: absolute;
  top: 35px;
  left: 190px;
}

.line {
  border-bottom: 3px solid #7a7a7a;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 60px;
}

.area_text {
  font-family: var(--font-family-m_plus_1);
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  position: absolute;
  top: 35px;
  left: 20px;
}

.image_kuma {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -50px;
  left: 400px;
}

.image_logo {
  width: 12%;
  height: auto;
  position: absolute;
  top: -60px;
  left: 1745px;
}

.text_by {
  color: #0c60ac;
  font-size: 120%;
  position: absolute;
  top: 6px;
  left: 1700px;
}

.image_byaf {
  width: 12%;
  height: 65px;
  position: absolute;
  top: -5px;
  left: 1720px;
}

.table {
  width: 100%;
  position: relative;
  top: 20px;
  left: 60px;
}

.hanrei {
  flex-direction: column;
  align-items: center;
  width: 800px;
  min-height: 120px;
  display: flex;
  position: relative;
  top: 15px;
  left: 20px;
}

.keisan, .Text {
  position: relative;
  top: 25px;
}

.Graph {
  width: 100%;
  position: relative;
  top: 80px;
  left: 17px;
}

.jepxchart {
  border: 1px solid #d9d9d9;
  width: 640px;
  height: 390px;
  position: relative;
}

.LineChart {
  position: absolute;
  top: 75px;
}

.header_chart {
  background-color: #f2f2f2;
  width: 100%;
  height: 50px;
}

.bold-text {
  font-weight: bold;
}
/*# sourceMappingURL=index.f43220f8.css.map */
